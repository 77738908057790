import { FC } from 'react';
import styled from 'styled-components';
import { formatFromUtc } from 'utils/dateFormating';
import { Version } from '../../types';
import { VersionTableCarousel } from './VersionTableCarousel';
import { useAppSelector } from 'store/hooks';

const VersionRowContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CarouselSpacer = styled.div`
  min-width: 2.625rem;
  max-width: 2.625rem;
  height: 2.625rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const StyledTableHeadingCells = styled.div<{ width?: string; isLast?: boolean }>`
  ${({ width }) => width && `min-width: ${width}; max-width: ${width};`}
  height: 2.625rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  ${({ isLast }) => isLast && 'margin-right: 0;'}
`;

interface VersionRowProps {
  version: Version;
  scrollPosition: number;
}

export const VersionRow: FC<VersionRowProps> = ({ version, scrollPosition }) => {
  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);
  const [formatedDay, formatedHour] = formatFromUtc(
    new Date(`${version.fipDate}Z`),
    userTimezone?.timeZone,
  ).split(' ');

  return (
    <VersionRowContainer>
      <StyledTableHeadingCells width="2.5rem">{version.version}</StyledTableHeadingCells>
      <StyledTableHeadingCells width="6rem">{formatedDay}</StyledTableHeadingCells>
      <StyledTableHeadingCells width="5rem">{formatedHour}</StyledTableHeadingCells>
      <StyledTableHeadingCells width="4rem" isLast>
        {version.fipUser}
      </StyledTableHeadingCells>
      <CarouselSpacer />
      <VersionTableCarousel scrollPosition={scrollPosition} version={version} />
      <CarouselSpacer />
    </VersionRowContainer>
  );
};
